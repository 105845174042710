<script lang="ts">
	export let videoUrl: string,
		videoTitle: string,
		videoDescription: string,
		tags: string[],
		thumbnail: string,
		isCard: boolean;

	function htmlToElement(html) {
		var template = document.createElement('template');
		html = html.trim();
		template.innerHTML = html;
		return template.content.firstChild;
	}

	async function oEmbedVideo() {
		try {
			return await fetch(`http://www.youtube.com/oembed?url=${videoUrl}&format=json`)
				.then((resp) => resp.json())
				.then((data) => {
					let iframe = htmlToElement(data.html);
					iframe.removeAttribute('width');
					iframe.removeAttribute('height');
					iframe.classList.add('w-full');
					iframe.classList.add('rounded-xl');
					data.html = iframe.outerHTML;
					return data;
				});
		} catch {
			return null;
		}
	}
</script>

<div class="flex flex-col w-full place-content-start {isCard ? 'card' : ''}">
	<a class="aspect-w-16 aspect-h-9" href={videoUrl} target="_blank">
		<div class="h-full w-full">
			<img src={thumbnail} alt="Video" class="rounded-2xl object-cover h-full w-full z-0" />
			<img
				src="/img/videos/play.png"
				alt=""
				class="absolute left-0 right-0 top-0 bottom-0 m-auto"
			/>
		</div>
	</a>

	{#if !isCard}
		<div class="flex flex-row flex-wrap mt-1">
			{#each tags as tag}
				<div class="mr-1 mt-1 px-1 py-0.5 rounded-md bg-think-100 text-think-700">
					{tag}
				</div>
			{/each}
		</div>
	{/if}

	<a
		class="text-gray-brand-500 text-2xl font-semibold mt-2 line-clamp-2"
		href={videoUrl}
		target="_blank"
	>
		{videoTitle || ''}
	</a>

	{#if isCard}
		<span class="text-gray-brand-500 mt-3 line-clamp-3">
			{videoDescription || ''}
		</span>
	{/if}
</div>

<style>
	.card {
		@apply rounded-2xl p-5;
	}
</style>
